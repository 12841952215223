import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from 'vue-gtag'

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
  path: "/",
  name: "Home",
  component: () => import( /* webpackChunkName: "home" */ "../views/Home/home.vue"),
  meta: {
    title: '在eSimart.com購買eSIM - 全球eSIM銷售'
  }
},{
  path: "/tf",
  name: "tf",
  component: () => import( /* webpackChunkName: "home" */ "../views/Home/home.vue"),
  meta: {
    title: '在eSimart.com購買eSIM卡 - 全球eSIM銷售'
  }
},
  {
    path: "/details",
    name: "Details",
    component: () => import( /* webpackChunkName: "details" */ "../views/Product/details.vue"),
    meta: {
      // title: '產品詳情'
    }
  },
  {
    path: "/product",
    name: "Product",
    component: () => import( /* webpackChunkName: "details" */ "../views/Product/list.vue"),
    meta: {
      title: 'eSIM產品 - eSImart'
    }
  },
  {
    path: "/search",
    name: "Search",
    component: () => import( /* webpackChunkName: "details" */ "../views/Search/search.vue"),
    meta: {
      title: '搜索您的eSIM - eSImart'
    }
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import( /* webpackChunkName: "details" */ "../views/Cart/cart.vue"),
    meta: {
      title: '購物車 - eSImart',
      requireAuth: false
    }
  },
  {
    path: "/closing",
    name: "Closing",
    component: () => import( /* webpackChunkName: "details" */ "../views/Cart/closing.vue"),
    meta: {
      title: '支付 - eSImart',
      requireAuth: false
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import( /* webpackChunkName: "details" */ "../views/test.vue"),
    meta: {
      title: '結帳',
      requireAuth: false
    }
  },
  {
    path: "/paymentResult",
    name: "paymentResult",
    component: () => import( /* webpackChunkName: "details" */ "../views/Cart/paymentResult.vue"),
    meta: {
      title: '付款結果 - eSImart',
      requireAuth: false
    }
  },

  {
    path: "/exchange",
    name: "exchange",
    component: () => import( /* webpackChunkName: "details" */ "../views/exchange/index.vue"),
    meta: {
      title: '兌換頁面 - eSImart'
    }
  },
  {
    path: "/exchangeDetail",
    name: "exchangeDetail",
    component: () => import( /* webpackChunkName: "details" */ "../views/exchange/detaile.vue"),
    meta: {
      title: '兌換頁面 - eSImart'
    }
  },
  {
    path: "/personal",
    name: "Personal",
    component: () => import( /* webpackChunkName: "details" */ "../views/Personal/personal.vue"),
    meta: {
      title: '個人中心 - eSImart',
      requireAuth: true
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import( /* webpackChunkName: "details" */ "../views/About/privacy.vue"),
    meta: {
      title: 'eSIM隱私政策 - eSImart'
    }
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import( /* webpackChunkName: "details" */ "../views/About/terms.vue"),
    meta: {
      title: '條款和條件 - eSImart'
    }
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import( /* webpackChunkName: "details" */ "../views/About/contactUs.vue"),
    meta: {
      title: '聯絡我們 - eSImart'
    }
  },
  {
    path: "/tutorial",
    name: "tutorial",
    component: () => import( /* webpackChunkName: "details" */ "../views/Support/tutorial.vue"),
    meta: {
      title: 'eSIM 安裝教學 - eSImart'
    }
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import( /* webpackChunkName: "details" */ "../views/Support/faq.vue"),
    meta: {
      title: '常見問題 - eSImart'
    }
  },
  // {
  //   path: "/tutorial",
  //   name: "tutorial",
  //   component: () => import(/* webpackChunkName: "details" */ "../views/Tutorial/tutorial.vue"),
  //   meta: {
  //     title: 'Tutorial'
  //   }
  // },
  // {
  //   path: "/contactus",
  //   name: "contactus",
  //   component: () => import(/* webpackChunkName: "details" */ "../views/contactUs/index.vue"),
  //   meta: {
  //     title: 'Contactus'
  //   }
  // },
  // {
  //   path: "/helpCenter",
  //   name: "helpCenter",
  //   component: () => import( /* webpackChunkName: "details" */ "../views/helpCenter/index.vue"),
  //   meta: {
  //     title: 'HelpCenter - eSImart'
  //   }
  // },
  {
    path: "/works",
    name: "works",
    component: () => import( /* webpackChunkName: "details" */ "../views/works.vue"),
    meta: {
      title: '安裝您的eSIM卡 - eSImart'
    }
  },
  {
    path: "/affiliate",
    name: "affiliate",
    component: () => import( /* webpackChunkName: "details" */ "../views/affiliate.vue"),
    meta: {
      title: '歡迎來到連接的未來 - eSImart'
    }
  },
  {
    path: "/affiliateSign",
    name: "affiliateSign",
    component: () => import( /* webpackChunkName: "details" */ "../views/affiliateSign.vue"),
    meta: {
      title: '註冊成為聯盟會員 - eSImart'
    }
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import( /* webpackChunkName: "details" */ "../views/aboutUs.vue"),
    meta: {
      title: '關於我們 - eSImart'
    }
  },

  {
    path: "/buzz",
    name: "buzz",
    component: () => import( /* webpackChunkName: "details" */ "../views/buzz/index.vue"),
    meta: {
      title: 'eSIM Buzz - eSImart'
    }
  },
  {
    path: "/buzz",
    name: "buzz",
    component: () => import( /* webpackChunkName: "details" */ "../views/buzz/index.vue"),
    meta: {
      title: 'eSIM Buzz - eSImart'
    }
  },
  {
    path: "/buzzDetail",
    name: "buzzDetail",
    component: () => import( /* webpackChunkName: "details" */ "../views/buzz/details.vue"),
    meta: {
      title: 'eSIM Buzz詳情 - eSImart'
    }
  },

  {
    path: '/404',
    name:'404',
    component: () => import("../views/blank/index.vue"),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: './',
  routes,
  // 切换路由后滚动条置顶
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
});
router.beforeEach((to, from, next) => {
  // if (window.gtag) {
  //   // console.log('window.gtag',window.gtag)
  //   // console.log('to.fullPath',to.fullPath)
  //   window.gtag('config', 'G-9D7WEGV5T7', { 'page_path': to.fullPath});
  // }
  next();
})
export default router;

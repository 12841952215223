<template>
  <div class="Register">
    <h1 class="box-h1">創建您的帳戶</h1>
    <div class="box-register">
      <span>登入會員中心以查找更多資訊</span>
    </div>

    <!-- 注册第一步 -->
    <div v-if="step == 1">
      <div class="box-input">
        <input type="text" v-model="email" placeholder="您的電子郵件作為帳戶名稱">
      </div>
      <div class="box-input box-code">
        <input type="text" v-model="code" placeholder="驗證碼">
        <div class="sendCode" :style="{ color: disabledFlag ? '#5CE9C7' : '#f4f4f4', cursor: disabledFlag ? 'pointer' : 'no-drop' }"
          @click="disabledFlag ? sendYZM() : ''"> <div class="_send" :class="{'flag':!disabledFlag}">{{ texttime }}</div></div>
      </div>
      <div class="box-login" @click="nextStep">下一步：設定密碼</div>
      <div class="box-google">
        <div @click="googleLogin">
          <img src="@/assets/image/user/google.png" alt="google">
          使用 Google 帳戶登入
        </div>
      </div>
    </div>

    <!-- 注册第二步 -->
    <div v-if="step == 2">
      <div class="box-input">
        <input type="text" v-model="passWord" placeholder="請輸入您的密碼（6-20個字符，包含字母和數字的組合）">
      </div>
      <div class="box-input">
        <input type="text" v-model="passWordTwo" placeholder="重複密碼">
      </div>
      <div class="box-login" @click="register">註冊</div>
    </div>
  </div>
</template>

<script>
import { getyzm, Validation, Registration, login } from '@/request/api'
export default {
  name: 'Register',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      step: 1,
      email: '',
      code: '',
      // texttime: 'Send verification code',
      texttime: 'SEND',
      time_count: 60,
      disabledFlag: true,
      passWord: '',
      passWordTwo: '',
    }
  },
  methods: {
    // 下一步
    async nextStep() {
      let data = {
        account: this.email,
        code: this.code,
        type:1
      }
      let res = await Validation(data)
      if (res.code == 1) {
        this.step = 2
      }
    },
    // 完成註冊
    async register() {
      let req = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      if (req.test(this.passWord) && this.passWord == this.passWordTwo) {
        let data = {
          account: this.email,
          password: this.passWord,
          confirm_pass: this.passWordTwo
        }
        let res = await Registration(data);
        if (res.code == 1) {
           this.$emit('typeChange', 1)
        }
      } else if (!req.test(this.passWord)) {
        this.$message({
          type: 'warning',
          message: '密碼長度為6到20個字母和數字組合'
        })
      } else if (this.passWord != this.passWordTwo) {
        this.$message({
          type: 'warning',
          message: '輸入的兩次密碼不一致，請重新輸入'
        })
      }
    },
    // 谷歌登入
    googleLogin() {
      console.log('aa')
        // window.location = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=online&client_id=128501738159-5qdo2hj97pk6pmb9sgsbhffpt10k366h.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fesimart.twczw.website%2Fapi%2FFront%2Fgoogle_login&state&scope=email%20profile&approval_prompt=auto'
        window.location = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=online&client_id=866710459170-kjghebt559shf6or8cu6sl6vhdp87upj.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapi.esimart.com%2Fapi%2FFront%2Fgoogle_login&state&scope=email%20profile&approval_prompt=auto'
    },
    //發送驗證碼
    async sendYZM() {
      if (this.email) {
        let data = {
          account: this.email,
          type:1
        }
        let res = await getyzm(data);
        this.timer = setInterval(() => {
          if (this.time_count > 0) {
            this.disabledFlag = false;
            this.time_count--;
            // this.texttime = "重新驗證 " + this.time_count + "s";
            // this.texttime = "Waiting " + this.time_count + "sec before resending the verification code";
            this.texttime =  this.time_count + "s";
          } else {
            // (this.texttime = "重新驗證"), clearInterval(this.timer);
            // this.texttime = "Waiting " + this.time_count + "sec before resending the verification code";
            this.texttime = this.time_count + "s";
            this.timer = null;
            this.time_count = 60;
            this.disabledFlag = true;
          }
        }, 1000);
        // console.log(res)
        if(res.code === 4){
          this.$message({
            type: 'warning',
            message: '這個郵件已經被註冊'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '請填寫您的電子郵件'
        })
      }

    }
  }
}

</script>

<style lang="less" scoped>
.Register {
  .box-h1 {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    color: #FFFFFF;

  }

  .box-register {
    margin: 10px 0 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #00A2FD;
    }
  }

  .box-input {
    margin-bottom: 17px;
    width: 100%;
    height: 50px;

    input {
      padding: 0 16px;
      width: 100%;
      height: 100%;
      background: #2E2E2E;
      border: 1px solid #2E2E2E;
      border-radius: 10px;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #D9D9D9;
    }

    input:focus {
      border: 1px solid #5CE9C7;
    }

    input::-webkit-input-placeholder {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #616161;
    }

    input::-moz-placeholder {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #616161;
    }

    input::-moz-placeholder {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #616161;
    }

    input::-ms-input-placeholder {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #616161;
    }
  }

  .box-code {
    position: relative;

    input {
      padding-right: 120px;
    }

    .sendCode {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 16px;
      width: 100px;
      height: 100%;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #5CE9C7;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .box-login {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 58px auto 30px;
    width: 216px;
    height: 37px;
    background: #0B57C9;
    border-radius: 20px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
  }

  .box-google {
    display: flex;
    justify-content: center;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #D9D9D9;

      img {
        margin-right: 12px;
        width: 35px;
        height: 35px;
      }
    }
  }
}
.Register .box-code .sendCode{
  right: 6px;
  justify-content: flex-end
}
.Register .box-code .sendCode ._send{
  background: #0B57C9;
  padding: 11px 12px;
  border-radius: 6px;
  color: #ffffff;
  min-width: 91px;
  text-align: center;
}
.Register .box-code .sendCode ._send.flag{
  background: #252525;
}
@media screen and (max-width:750px) {
  .Register {
    .box-h1 {
      padding-left: 7px;
    }

    .box-register {
      padding-left: 7px;
      margin: 10px 0 16px;
    }

    .box-input {
      margin-bottom: 24px;
    }

    .box-login {
      margin: 115px auto 30px;
      width: 100%;
    }
  }
}
</style>

<template>
  <div id="app" :style="{paddingTop:$route.path == '/exchange' || $route.path == '/exchangeDetail'?'20px':'80px'}">
    <nav-header v-if="$route.path != '/404' && $route.path != '/exchange' && $route.path != '/exchangeDetail'" ></nav-header>
    <router-view v-if="isRouterAlive"></router-view>
    <nav-footer v-if="$route.path != '/404' && $route.path != '/exchange' && $route.path != '/exchangeDetail'" ></nav-footer>
    <div class="float-box">
      <ul>
        <li>
          <a href="https://line.me/R/ti/p/@esimart?from=page&searchId=esimart" target="_blank">
<!--            <svg t="1716778004016" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3942" width="50" height="50"><path d="M608.20001 408.4v142.2c0 3.6-2.8 6.4-6.4 6.4h-22.8c-2.2 0-4.2-1.2-5.2-2.6l-65.2-88v84.4c0 3.6-2.8 6.4-6.4 6.4h-22.8c-3.6 0-6.4-2.8-6.4-6.4v-142.2c0-3.6 2.8-6.4 6.4-6.4H502.00001c2 0 4.2 1 5.2 2.8l65.2 88v-84.4c0-3.6 2.8-6.4 6.4-6.4h22.8c3.6-0.2 6.6 2.8 6.6 6.2z m-164-6.4h-22.8c-3.6 0-6.4 2.8-6.4 6.4v142.2c0 3.6 2.8 6.4 6.4 6.4h22.8c3.6 0 6.4-2.8 6.4-6.4v-142.2c0-3.4-2.8-6.4-6.4-6.4z m-55 119.2h-62.2v-112.8c0-3.6-2.8-6.4-6.4-6.4h-22.8c-3.6 0-6.4 2.8-6.4 6.4v142.2c0 1.8 0.6 3.2 1.8 4.4 1.2 1 2.6 1.8 4.4 1.8h91.4c3.6 0 6.4-2.8 6.4-6.4v-22.8c0-3.4-2.8-6.4-6.2-6.4zM728.20001 402h-91.4c-3.4 0-6.4 2.8-6.4 6.4v142.2c0 3.4 2.8 6.4 6.4 6.4h91.4c3.6 0 6.4-2.8 6.4-6.4v-22.8c0-3.6-2.8-6.4-6.4-6.4H666.00001v-24h62.2c3.6 0 6.4-2.8 6.4-6.4V468c0-3.6-2.8-6.4-6.4-6.4H666.00001v-24h62.2c3.6 0 6.4-2.8 6.4-6.4v-22.8c-0.2-3.4-3-6.4-6.4-6.4zM960.00001 227.4V798c-0.2 89.6-73.6 162.2-163.4 162H226.00001c-89.6-0.2-162.2-73.8-162-163.4V226c0.2-89.6 73.8-162.2 163.4-162H798.00001c89.6 0.2 162.2 73.6 162 163.4z m-123.2 245.2c0-146-146.4-264.8-326.2-264.8-179.8 0-326.2 118.8-326.2 264.8 0 130.8 116 240.4 272.8 261.2 38.2 8.2 33.8 22.2 25.2 73.6-1.4 8.2-6.6 32.2 28.2 17.6 34.8-14.6 187.8-110.6 256.4-189.4 47.2-52 69.8-104.6 69.8-163z" fill="#1afa29" p-id="3943"></path></svg>-->
          <img src="@/assets/image/line-right.png" alt="line" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import navHeader from "@/components/NavHeader";
import navFooter from "@/components/NavFooter";
// import messenger from "@/components/messenger";
export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      //定义状态
      isRouterAlive: true
    };
  },
  // watch: {
  //   '$route' (to, from) {
  //     // this.$gtag.event('page_view',{ page_path: this.$route.path,'page_title':document.title })
  //     // console.log('this.$route.path',this.$route)
  //     // console.log(document.title)
  //   }
  // },
  mounted(){

  },
  metaInfo: {
    meta: [
      { name: 'Title', content: '全球eSIM方案盡在eSImart｜出國也能享受高網速', vmid: 'Title' },
      { name: 'Author', content: 'eSImart', vmid: 'Author' },
      { name: 'Subject', content: '銷售全球eSIM方案', vmid: 'Subject' },
      { name: 'Description', content: '下單15分鐘自動出貨，省去等待、換卡安裝的惱人過程！eSImart擁有10年以上經驗，堅持與在地電信龍頭合作，讓你旅遊期間上網也能享受超快網速，立即查看更多網卡優惠方案。', vmid: 'Description' },
      { name: 'Keywords', content: 'esim / 吃到飽esim / 網卡 / 4G網卡 / 4G esim', vmid: 'Keywords' },
      { name: 'Language', content: 'zh_TW', vmid: 'Language' },
      { property: 'og:type', content: 'article', vmid: 'og:type' },
      { property: 'og:image', content: '/favicon.ico', vmid: 'og:image' },
      { property: 'og:site_name', content: '全球eSIM方案盡在eSImart｜出國也能享受高網速', vmid: 'og:site_name' },
      { property: 'og:description', content: '下單15分鐘自動出貨，省去等待、換卡安裝的惱人過程！eSImart擁有10年以上經驗，堅持與在地電信龍頭合作，讓你旅遊期間上網也能享受超快網速，立即查看更多網卡優惠方案。', vmid: 'og:description' },
      { property: 'og:url', content: 'https:/tw.esimart.com', vmid: 'og:url' },
      { property: 'og:title', content: '全球eSIM方案盡在eSImart｜出國也能享受高網速', vmid: 'og:title' }
    ]
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }

  },

  components: {
    navHeader,
    navFooter
  },
  computed: {
    isWhitePath: {
      get() {
        let arr = ['/details', '/product','/cart','/closing','/paymentResult','/search','/personal']
        if (arr.includes(this.$route.path)) {
          return false
        } else {
          return true
        }
      }
    }
  },

}
</script>
<style lang="less" scoped>
#app {
  padding-top: 82px;
  background: #0A0A14;
  //min-height: calc(100vh - 82px);
}
.float-box{
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 9999 !important;
  ul{
    padding: 0;
    margin: 0;
  }
  li{
    a{
      img{
        width: 50px;
        height: 63px;
        object-fit: cover;
      }
    }
  }

}
@media screen and (max-width: 800px) {
  #app {
    padding-top: 50px;
    //min-height: calc(100vh - 50px);
  }
  .float-box{
    bottom: 20px;
  }
}
</style>
<style>
html{background: #0A0A14;}
</style>

<template>
    <div class="ForgetPassword">
      <h1 class="box-h1">忘記密碼</h1>
      <div class="box-register">
        <span>驗證您的電子郵件並重設您的密碼</span>
      </div>

      <!-- 忘记密码第一步 -->
      <div v-if="step == 1">
        <div class="box-input">
          <input type="text" v-model="email" placeholder="請輸入您的電子郵件">
        </div>
        <div class="box-input box-code">
          <input type="text" v-model="code" placeholder="請輸入您收到的驗證碼">
          <div class="sendCode" :style="{ color: disabledFlag ? '#5CE9C7' : '#f4f4f4', cursor: disabledFlag ? 'pointer' : 'no-drop' }"  @click="disabledFlag ? sendYZM() : ''"><div class="_send" :class="{'flag':!disabledFlag}">{{ texttime }}</div></div>
        </div>
        <div class="box-login" @click="nextStep">下一步：設置密碼</div>
      </div>

      <!-- 忘记密码第二步 -->
      <div v-if="step == 2">
        <div class="box-input">
          <div :class="{'active':passWordType === 'password'}" class="_showEyes" @click="showPwd(1)"></div>
          <input ref="passWord" :type="passWordType" v-model="passWord" placeholder="請輸入您的密碼（6-20個字符，包含字母和數字的組合）">
        </div>
        <div class="box-input">
          <div :class="{'active':passWordType === 'password'}" class="_showEyes" @click="showPwd(2)"></div>
          <input ref="passWordTwo" :type="passWordTwoType" v-model="passWordTwo" placeholder="重複密碼">
        </div>
        <div class="box-login" @click="register">重設密碼</div>
      </div>
    </div>
</template>

<script>
import {forgotPassword,getyzm,Validation,userInformation,login} from '@/request/api'
export default {
  name: 'ForgetPassword',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      step: 1,
      email: '',
      code: '',
      // texttime: 'Send verification code',
      texttime: 'SEND',
      time_count: 60,
      disabledFlag: true,
      passWord:'',
      passWordTwo:'',
      passWordType: 'password',
      passWordTwoType: 'password',
    }
  },
  methods: {
    // 下一步
   async nextStep() {
      let data = {
        account: this.email,
        code: this.code,
        type:2
      }
      let res = await Validation(data)
      if (res.code == 1) {
        this.step = 2
      }
    },
    // 完成註冊
   async register() {
    let req = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      if (req.test(this.passWord) && this.passWord == this.passWordTwo) {
      let data = {
        account:this.email,
        password:this.passWord,
        confirm_pass:this.passWordTwo,
      }
      let res = await forgotPassword(data)
      if (res.code == 1) {
           this.login()
      }

    } else if (!req.test(this.passWord)) {
        this.$message({
          type: 'warning',
          message: '密碼長度為6-20個字母和數字組合'
        })
      } else if (this.passWord != this.passWordTwo) {
        this.$message({
          type: 'warning',
          message: '輸入的兩次密碼不相符，請重新輸入'
        })
      }
    },

    async login() {
      let data = {
        account: this.email,
        password: this.passWord
      }
      let rep = await login(data)
      if(rep.code == 1){
        window.localStorage.setItem('TOKEN_NAME', JSON.stringify(rep.data.token))
        this.getUserxinxi()
        this.$emit('close', false)
       this.$nextTick(() =>{
        this.$store.commit("changeStatus", true);
        this.$store.commit('changgetCart')
       })
        this.email = '';
        this.password = '';
        this.$router.push({
        path: '/personal',
        query: {}
      })
      }else{
        this.$message({
          type:'warning',
          message:rep.msg
        })
      }

    },

      //獲取用戶信息
   async getUserxinxi(){
        let res = await userInformation();
        window.localStorage.setItem('userInfo',JSON.stringify(res.data.user))
        this.$nextTick(() =>{
        this.$store.commit("changeuserInfo", JSON.stringify(res.data.user));
       })
    },

     //發送驗證碼
     async sendYZM() {
      if (this.email) {
        let data = {
          account: this.email,
          type:2
        }
        let res = await getyzm(data);
        this.timer = setInterval(() => {
          if (this.time_count > 0) {
            this.disabledFlag = false;
            this.time_count--;
            // this.texttime = "重新驗證 " + this.time_count + "s";
            // this.texttime = "Waiting " + this.time_count + "sec before resending the verification code";
            this.texttime = this.time_count + "s";
          } else {
            (this.texttime = "Waiting"), clearInterval(this.timer);
            this.timer = null;
            this.time_count = 60;
            this.disabledFlag = true;
          }
        }, 1000);
      } else {
        this.$message({
          type: 'warning',
          message: '請填寫您的電子郵件'
        })
      }

    },
    showPwd(n) {
      if (n === 1) {
        if (this.passWordType === 'password') {
          this.passWordType = ''
        } else {
          this.passWordType = 'password'
        }
        this.$nextTick(() => {
          this.$refs.passWord.focus()
        })
      } else if (n === 2) {
        if (this.passWordTwoType === 'password') {
          this.passWordTwoType = ''
        } else {
          this.passWordTwoType = 'password'
        }
        this.$nextTick(() => {
          this.$refs.passWordTwo.focus()
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .ForgetPassword {
    .box-h1 {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 46px;
      color: #FFFFFF;
    }
    .box-register {
      margin: 10px 0 34px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #00A2FD;
      }
    }
    .box-input {
      margin-bottom: 17px;
      width: 100%;
      height: 50px;
      input {
        padding: 0 16px;
        width: 100%;
        height: 100%;
        background: #2E2E2E;
        border: 1px solid #2E2E2E;
        border-radius: 10px;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #D9D9D9;
      }
      input:focus {
        border: 1px solid #5CE9C7;
      }
      input::-webkit-input-placeholder{
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #616161;
      }
      input::-moz-placeholder{
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #616161;
      }
      input::-moz-placeholder{
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #616161;
      }
      input::-ms-input-placeholder{
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #616161;
      }
    }
    .box-code {
      position: relative;
      input {
        padding-right: 120px;
      }
      .sendCode {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 16px;
        width: 100px;
        height: 100%;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #5CE9C7;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .box-login {
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 58px auto 30px;
      width: 216px;
      height: 37px;
      background: #0B57C9;
      border-radius: 20px;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
    }
  }

  .ForgetPassword .box-code .sendCode{
    right: 6px;
    justify-content: flex-end
  }
  .ForgetPassword .box-code .sendCode ._send{
    background: #0B57C9;
    padding: 11px 12px;
    border-radius: 6px;
    color: #ffffff;
    min-width: 91px;
    text-align: center;
  }
  .ForgetPassword .box-code .sendCode ._send.flag{
    background: #252525;
  }

@media screen and (max-width:750px) {
  .ForgetPassword {
    .box-h1 {
      padding-left: 7px;
    }
    .box-register {
      padding-left: 7px;
      margin: 10px 0 16px;
    }
    .box-input {
      margin-bottom: 24px;
    }
    .box-login {
      margin: 115px auto 30px;
      width: 100%;
    }
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/style/reset.css";
// import "@/utils/rem.js";
import "@/utils/smtp.js";
import animated from 'animate.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
Vue.use(ElementUI)
import { Select, Option, InputNumber, Input,Radio,Message} from 'element-ui';
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Radio)
Vue.use(InputNumber)
Vue.use(VueMeta, {
  attribute: 'v-m'
})
Vue.use(VueGtag, {
  config: {
    id: 'G-9D7WEGV5T7'
  }
}, router)

Vue.directive('currency', {
    bind(el, binding, vnode) {
        el.oninput = function(e) {
            if (!e.isTrusted) return
            let value = e.target.value.replace(/[^\d]/g, '')
            value = parseInt(value, 10)
            if (!isNaN(value)) {
                e.target.value = value.toLocaleString()
            } else {
                e.target.value = ''
            }
            vnode.componentInstance.$emit('input', e.target.value.replace(/\D/g, ''))
        }
    }
})


Vue.prototype.$message = Message;
Vue.use(animated)
Vue.config.productionTip = false;
// inject:['opnePopup']
// 路由守卫
router.beforeEach(async(to, form, next) => {
  if (to.meta.title) {
      document.title = to.meta.title
  } else {
      document.title = 'Buy eSIM Online - Global eSIMs for Sale at eSImart.com' // 此处写默认的title
  }
  if (to.meta.requireAuth) {
     if(window.localStorage.getItem('TOKEN_NAME')){
         next()
     }else{
         Message.warning('請先登入')
        next(form.fullPath)
     }
  }else{
    next()
  }
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div class="MenuType" v-if="show">
      <div class="tBox">
        <div class="box-type">
          <span  @click="goLink('/product')">eSIM方案</span>
        </div>
        <div class="box-type">
          <span  @click="goLink('/works')">安裝使用</span>
        </div>
        <div class="box-type">
          <span  @click="goLink('/faq')">常見問題</span>
        </div>
        <div class="box-type">
          <span  @click="goLink('/aboutUs')">關於我們</span>
        </div>
        <div class="box-type">
          <span  @click="goLink('/contactUs')">聯繫我們</span>
        </div>
        <div class="box-type">
          <span  @click="goLink('/affiliate')">加入我們</span>
        </div>
        <div class="box-type">
          <span  @click="goLink('/buzz')">eSIM Buzz</span>
        </div>
      </div>
      <div class="box">
        <h2 class="box-h2">分類</h2>
        <div class="box-type">
          <span v-for="(item,index) in fenlei"  :key='index' @click="goxiangqin(item.id)"> {{item.name}}</span>
        </div>
        <h2 class="box-h2">國家/地區</h2>
        <div v-for="(item,index) in areaList" :key="index">
        <h3 class="box-h3">{{item.name}}</h3>
        <div class="box-addr">
          <span v-for="(iten,i) in item.country" :key="i+1" @click="goxq(iten.id)">{{ iten.name }}</span>
        </div>
      </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MenuType',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    fenlei:{
      type: Array,
      default:()=>[]
    },
    areaList:{
      type: Array,
      default:()=>[]
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  methods:{
    goLink(link){
      this.$router.push({
        path:link,
      })
      this.$emit('menuTypeChange')
    },
    goxiangqin(id){
        this.$router.push({
           path:'/search',
           query:{
              typeId:id
           }
        })
        this.$emit('menuTypeChange')
    },
    goxq(id){
        this.$router.push({
           path:'/search',
           query:{
              areaId:id
           }
        })
        this.$emit('menuTypeChange')
    }
  }
}
</script>

<style lang="less" scoped>
.MenuType {
  position: fixed;
  top: 82px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 82px);
  background: rgba(0, 0, 0, 0.9);
  .box {
    padding: 44px 50px;
    margin: 0 auto;
    width: 1100px;
    .box-h2 {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      color: #00A2FD;
    }
    .box-type {
      margin: 11px 0 53px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span {
        cursor: pointer;
        margin: 16px 35px 0 0;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #FFFFFF;
      }
    }
    .box-h3 {
      padding: 27px 0 10px;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #5CE9C7;
    }
    .box-addr {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span {
        cursor: pointer;
        margin: 16px 35px 0 0;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #FFFFFF;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .MenuType {
    .box {
      width: 900px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .MenuType {
    .box {
      padding: 20px;
      width: 740px;
    }
  }
}
@media screen and (max-width: 800px) {
  .MenuType {
    top: 50px;
    height: calc(100% - 50px);
    .box {
      padding: 50px 25px;
      width: calc(100% - 50px);
      overflow: scroll;
      height: calc(100% - 100px);
    }
  }
}
</style>

<template>
  <div class="NavFooter">
    <div class="box">
      <div class="box-top">
        <div class="box-top-d">
          <img src="@/assets/image/footer/logo.png" alt="logo">
          <div></div>
          <span></span>
          <p>探索eSImart的eSIM的便利性。選購我們廣泛的eSIM產品系列，享受全球無縫連接。探索我們的漫遊數據解決方案，體驗無憂旅行。受惠於我們可靠的全球電信服務，無論您身在何處，都能保持連接。在eSimart找到符合您需求的完美eSIM解決方案</p>
          <span></span>
        </div>
        <div class="box-top-s">
          <h3>支援</h3>
          <div @click="$router.push('/product')">購買eSIM</div>
          <div @click="$router.push('/affiliate')">加入我們</div>
          <div @click="$router.push('/contactUs')">聯絡我們</div>
        </div>
        <div class="box-top-s">
          <h3>eSImart</h3>
          <div @click="$router.push('/tutorial')">安裝使用</div>
          <div @click="$router.push('/faq')">常見問題</div>
        </div>
        <div class="box-top-s">
          <h3>關於</h3>
          <div @click="$router.push('/privacy')">隱私政策</div>
          <div @click="$router.push('/terms')">服務條款</div>
          <div @click="$router.push('/terms?id=refundPolicy')">退貨政策</div>
        </div>
        <div class="box-top-s">
          <h3 style="opacity: 0;">海信科技股份有限公司</h3>
          <div> <a href="mailto:service@esimart.com">Email：service@esimart.com</a> </div>
          <div>服務時間：<span style="display: inline-block;margin-bottom: 20px">週一至週五<br><span style="position: absolute">09:00-24:00 (GMT+8)</span></span></div>
              <div style="display: flex;align-items: center;gap: 10px"><a href="https://lin.ee/QMNA7uj" target="_blank"><img src="@/assets/image/line.png" style="width: 35px;height: 35px"/></a><span id="textToCopy" @click="copyText('@eSImart')">@eSImart</span></div>
        </div>
      </div>
      <div class="box-line"></div>
      <div class="box-copyright">版權所有 2023 eSImart 保留所有權利</div>
<!--      <div class="box-copyright">© 2023 海興科技有限公司</div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "NavFooter",
  methods:{
    copyText(item) {
      let url = item;
      let copyInput = document.createElement('input');
      document.body.appendChild(copyInput);
      copyInput.setAttribute('value', url);
      copyInput.select();
      document.execCommand("Copy");
      this.$message.success("已複製Line ID！");
      copyInput.remove();
    }
  }
}
</script>

<style lang="less" scoped>
.NavFooter {
  width: 100%;
  background: #272727;
  .box {
    padding: 54px 13px 0;
    margin: 0 auto;
    width: 1174px;
    .box-top {
      display: flex;
      padding: 0 10px 50px;
      .box-top-d {
        margin-right: 90px;
        width: 340px;
        img {
          width: auto;
          height: 20px;
        }
        div {
          margin: 9px 0 21px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #00A2FD;
        }
        span {
          display: none;
        }
        p {
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #FFFFFF;
        }
      }
      .box-top-s {
        padding: 0 25px;
        user-select: none;

        h3 {
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          height: 23px;
          color: #00A2FD;
        }
        div {
          padding: 4px 0;
          cursor: pointer;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
          a{
            color: #FFFFFF;
          }
          &:first-of-type {
            margin-top: 18px;
          }
        }
        &:not(:last-of-type) {
          white-space: nowrap;
        }
      }
    }
    .box-line {
      width: 100%;
      height: 1px;
      background: #616161;
    }
    .box-copyright {
      padding: 18px 0 24px;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #FFFFFF;
    }
  }
}

// @media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {
  .NavFooter {
    .box {
      padding: 44px 13px 0;
      margin: 0 auto;
      width: 1000px;
      .box-top {
        padding: 0 10px 40px;
        .box-top-d {
          margin-right: 70px;
          width: 320px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .NavFooter {
    .box {
      padding: 44px 13px 0;
      margin: 0 auto;
      width: 780px;
      .box-top {
        padding: 0 10px 30px 0px;
        .box-top-d {
          margin-right: 50px;
          width: 300px;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .NavFooter {
    width: 100%;
    .box {
      padding: 0 20px;
      width: calc(100% - 40px);
      .box-top {
        padding: 33px 0 14px;
        display: flex;
        flex-wrap: wrap;
        .box-top-d {
          margin-right: 0;
          margin-bottom: 35px;
          width: 100%;
          div {
            margin: 10px 0 14px;
          }
          span {
            display: block;
            width: 100%;
            height: 1px;
            background: #2E2E2E;
          }
          p {
            margin: 24px 0;
          }
        }
        .box-top-s {
          padding: 0 0 0 10px;
          width: calc((100% - 30px) / 2);
        }
        .box-top-s:last-of-type {width: 100%;}
      }
      .box-line {
        background: #2E2E2E;
      }
      .box-copyright {
        padding: 22px 0 31px;
      }
    }
  }
}
// @media screen and (max-width:750px) {}
</style>
